.feature-project-parent {
  display: flex;
  justify-content: space-around;
  flex-flow: wrap;
  padding: 60px 0px 0px;
  margin-bottom: 80px;
}

.right-side-feature-project {
  width: 600px;
  margin-top: 80px;
}

.all-projects-parent {
  background-color: rgba(230, 234, 239, 0.784);
  padding: 30px 0px 80px;
}

.project-cards {
  display: flex;
  justify-content: space-evenly;
  padding: 20px 0px 40px;
  flex-flow: wrap;
}

#projects {
  margin-top: 20px;
  text-align: left;
  margin-bottom: 45px;
  color: #023a4a;
  font-size: 35px;
}

#wellness-gif {
  width: 500px;
}

.feature-titles {
  margin-top: 10px;
  color: #023a4a;
  font-size: 25px;
}

.feature-descp {
  margin: 30px 0px 30px;
  text-align: left;
  color: #023a4a;
}

#backend-desc {
  margin-top: -20px;
}

#view-feat-btn {
  padding: 10px;
  border: none;
}

#view-feat-btn:hover {
  transform: scale(1.1);
  transition: 0.2s;
}

a {
  font-weight: bold;
}

#all-projects-title {
  color: #023a4a;
  padding-top: 30px;
  padding-bottom: 20px;
  text-align: left;
  margin-left: 80px;
}

.individual-project-parent {
  width: 300px;
  background-color: #1688a78e;
  padding-bottom: 30px;
  color: #ffffff;
}

.individual-project-title {
  margin: 20px 2px 25px 0px;
  font-weight: bold;
  font-size: 20px;
}

.individual-project-desc {
  padding: 0px 10px;
  margin-bottom: 40px;
}

.project-img {
  width: 290px;
  padding-top: 10px;
}

.view-site-btn {
  border-style: none;
  padding: 8px;
  background-color: white !important;
  color: #074e62 !important;
}

.view-site-btn:hover {
  transform: scale(1.1);
  transition: 0.2s;
}

#mbta-btn {
  margin-top: 50px;
}

#wellness-all-btn {
  margin-top: 50px;
}

#pets-btn {
  margin-top: 25px;
}

@media only screen and (max-width: 1012px) {
  .individual-project-parent {
    margin: 0px 20px 70px 20px;
  }
}

@media only screen and (max-width: 625px) {
  .right-side-feature-project {
    margin: 50px 60px 20px 60px;
  }
}

@media only screen and (max-width: 525px) {
  #projects {
    margin: 20px 0px 45px 5px;
    font-size: 30px;
  }

  #wellness-gif {
    width: 400px;
  }
}

@media only screen and (max-width: 412px) {
  #projects {
    font-size: 25px;
  }

  #wellness-gif {
    width: 300px;
  }

  .feature-titles {
    font-size: 25px;
  }
}
