.experience-parent {
  display: flex;
  justify-content: space-around;
  flex-flow: wrap;
  padding: 0px 0px 80px;
  color: #023a4a;
}

.experiences-right-container {
  background-color: rgba(230, 234, 239, 0.784);
  padding: 30px;
  border-radius: 30px;
}

.experiences-right-title {
  margin-bottom: 40px;
}

#view-resume-btn {
  border-style: none;
  background-color: #023a4a;
  padding: 10px;
  color: #ffffff;
  margin: 20px 0px 20px;
}

#view-resume-btn:hover {
  transform: scale(1.1);
  transition: 0.2s;
}

.tech-stack-container {
  background-color: rgba(230, 234, 239, 0.784);
  padding: 30px;
  border-radius: 30px;
  width: 450px;
}

#tech-stack-title {
  margin-bottom: 30px;
}

.experience-paragraph {
  width: 400px;
  text-align: left;
}

.tech-stack-list {
  line-height: 60px;
}

@media only screen and (max-width: 912px) {
  .experiences-right-container {
    width: 450px;
  }
}

@media only screen and (max-width: 911px) {
  .experiences-right-container {
    margin-top: 50px;
    width: 450px;
  }
}

/* @media only screen and (max-width: 467px) {
  .experience-paragraph {
    padding: 10px 60px 0px 60px;
  }

  .experiences-right-container {
    padding: 20px;
  }

  .tech-stack-container {
    width: 390px;
  }
} */

/* @media only screen and (max-width: 467px) {
  .experiences-right-title {
    font-size: 25px;
  }

  #tech-stack-title {
    font-size: 25px;
  }

  .experiences-right-container {
    width: 300px;
  }

  .experience-paragraph {
    padding-left: 15px;
    width: 350px;
  }

  .tech-stack-container {
    width: 310px;
  }
} */
