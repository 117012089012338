.about-parent {
  display: flex;
  justify-content: space-around;
  flex-flow: wrap;
  background-color: #023a4a;
  padding: 80px 10px 100px;
  text-align: left;
  clip-path: polygon(0 0, 100% 0, 100% 93%, 0 80%);
  color: #f6f5f5;
}

.about-left-container {
  width: 700px;
}

#jphoto {
  height: 300px;
  border-radius: 30px;
}

#my-projects-btn {
  padding: 10px;
  border: none;
  background-color: #ffffff;
  color: #023a4a;
  font-weight: bold;
}

#my-projects-btn:hover {
  transform: scale(1.1);
  transition: 0.2s;
}

@media only screen and (max-width: 1012px) {
  .about-parent {
    padding: 80px 10px 200px;
  }
}

@media only screen and (max-width: 729px) {
  .about-left-container {
    margin: 0px 50px 20px 50px;
  }
}

@media only screen and (max-width: 467px) {
  #jess-greeting {
    font-size: 35px;
    margin-bottom: 30px;
  }

  #brand-statement {
    margin-bottom: 30px;
  }

  #jphoto {
    margin-top: 20px;
    height: 250px;
  }
}
