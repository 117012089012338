.footer-parent {
  padding: 40px 0px 40px;
  background-color: #023a4a;
  clip-path: polygon(0 1%, 100% 50%, 100% 100%, 0% 100%);
  text-align: center;
}

.bottom-section {
  background-color: rgba(230, 234, 239, 1);
}

.footer-photo {
  height: 35px;
  width: 35px;
  margin: 40px 20px 10px;
}
