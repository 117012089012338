.nav-section {
  display: flex;
}

.sticky-top {
  position: sticky;
  background-color: #023a4a;
}

.nav-tabs {
  color: #f6f5f5 !important;
  margin-left: 30px;
}

#basic-navbar-nav {
  flex-direction: row-reverse;
  margin-right: 5%;
}

.navbar-light .navbar-nav .nav-link {
  border: 0;
}

.navbar-light .navbar-nav .nav-link:hover {
  transform: scale(1.1);
  transition: 0.2s;
}

.toggle {
  background-color: white !important;
}

#jessica {
  color: #ffffff;
  font-size: 35px;
  font-weight: bold;
  margin-left: 20px;
}
