.contact-parent {
  padding: 40px 0px 40px;
  color: #023a4a;
}

#contact-title {
  margin-bottom: 30px;
}

@media only screen and (max-width: 625px) {
  .contact-parent {
    margin: 50px 60px 0px 60px;
  }
}
